<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">收益对账</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-date-range label="账单时间" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" :clearable="false" />
                <le-company-under-select label="商户" placeholder="请选择（可输入搜索）" :multiple="true" :collapse="true" v-model="companyId"></le-company-under-select>
                <le-switch label="查询关联商户" labelWidth="100px" style="height:56px" v-model="isRelevance" :acValue="true" :inacValue="false"></le-switch>
            </le-search-form>
            <le-pagview ref="financeSYRZ" @setData="setTableData" :pagerCount="5" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeSYRZList">
                <el-table ref="financeSYRZList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" label="商户" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="countDate" label="分账日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.countDate?row.countDate:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" label="总分账金额" min-width="123">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>总分账金额</span>
                                <el-tooltip class="item" effect="dark" content="" placement="top">
                                    <div slot="content">
                                        包含余额支付（用户余额，企业余额），<br />
                                        渠道支付，红包，赠送，毛豆赊交易完成<br />
                                        订单，且包含已退款金额（储值不算交易<br />
                                        额，储值消费的算）
                                    </div>
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="分账金额" min-width="123">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>分账金额</span>
                                <el-tooltip class="item" effect="dark" content="充电订单（订单完成为准）:余额支付，支付渠道支付、储值卡" placement="top">
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="入账状态" 
                        min-width="103"
                        column-key="incomeStatus"
                        :filter-multiple='false'
                        :filters="incomeStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeStatus | initDic(incomeStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="入账类型" 
                        min-width="103"
                        column-key="incomeType"
                        :filter-multiple='false'
                        :filters="incomeTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeType | initDic(incomeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myIncomePrice" label="自营项目收益" min-width="123">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>自营项目收益</span>
                                <el-tooltip class="item" effect="dark" content="项目的归属商户" placement="top">
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.myIncomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="agentIncomePrice" label="代理项目收益" min-width="123">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>代理项目收益</span>
                                <el-tooltip class="item" effect="dark" content="项目的参与商户" placement="top">
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.agentIncomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getBillsInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <!-- <div class="a-flex-rfsc" style="margin-right: 40px">
                            <span class="a-fs-12 a-c-normal">交易总额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.orderPriceTotal)}}</span>
                        </div> -->
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">入账总额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.incomePriceTotal)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.backCompanyIncomePage,
                    method: "post",
                    params: {
                        companyId: [],
                        endDate: "",
                        startDate: "",
                        incomeType: '',
                        incomeStatus: ''
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
                incomeStatusDic: [],//入账状态
                incomeTypeDic: [],//
                isRelevance: false,
                companyId: [],
                relevanceCompanyList: [],//
            }
        },
        watch: {
            companyId: {
                handler (val) {
                    this.pageParam.params.companyId = []
                    this.getCompanyRelevanceCompanyList()
                }
            }
        },
        created () {
            this.$getDic('incomeStatus').then(res=>{ this.incomeStatusDic = res; })
            this.$getDic('incomeType').then(res=>{ this.incomeTypeDic = res; })
            this.pageParam.params.startDate = this.$getDay.getTodayBeforeDays(6) + ' 00:00:00'
            this.pageParam.params.endDate = this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: [],
                    endDate: this.$getDay.getTodayBeforeDays(0) + ' 00:00:00',
                    startDate: this.$getDay.getTodayBeforeDays(6) + ' 00:00:00',
                    incomeStatus: ''
                };
                this.companyId = []
                this.relevanceCompanyList = []
                this.$refs['financeSYRZList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                if(this.isRelevance){
                    this.pageParam.params.companyId = this.companyId.concat(this.relevanceCompanyList)
                }else{
                    this.pageParam.params.companyId = this.companyId
                }
                this.$refs['financeSYRZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 查看收益账单详情
            getBillsInfo (datas) {
                this.$router.push({
                    path: '/finance/finance-syrz-details',
                    query: {
                        companyId: datas.companyId,
                        startDate: datas.countDate,
                        endDate: datas.countDate,
                        incomeType: datas.incomeType,
                        incomeStatus: datas.incomeStatus,
                    }
                })
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='incomeStatus'){
                        if(value[key].length==0){
                            this.pageParam.params.incomeStatus = ''
                        }else{
                            this.pageParam.params.incomeStatus = value[key][0]
                        }
                    }
                    if(key=='incomeType'){
                        if(value[key].length==0){
                            this.pageParam.params.incomeType = ''
                        }else{
                            this.pageParam.params.incomeType = value[key][0]
                        }
                    }
                }
                this.$refs['financeSYRZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            getCompanyRelevanceCompanyList () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getCompanyRelevanceCompanyList,
                    method: "post",
                    showLoading: false,
                    params: {
                        companyIdList: this.companyId
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.relevanceCompanyList = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 6,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 收益入账表',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>